type GetProductInputSourceProps = {
  brandSlug: string
  typeGroup?: string
  theEdit?: boolean
  theShop?: boolean
}

const getBrandLinkSearchParams = ({ brandSlug, typeGroup, theEdit, theShop }: GetProductInputSourceProps) => {
  if (brandSlug === 'drift') {
    return `?productInputSource=THE_EDIT&sourceProductGroup=${typeGroup || 'GiftSet'}`
  }

  if (theEdit || theShop) {
    return '?tab=fullBottle'
  }

  return ''
}

export default getBrandLinkSearchParams
