import { useQuery } from 'apollo-client'
import dayjs from 'date'
import { constants } from 'helpers'
import { useFt } from 'hooks'
import { useUser } from 'modules/user'

import websiteTakeoverQuery from './graph/websiteTakeover.strapi.graphql'


const useStrapiWSTOData = () => {
  const isStrapiWebsiteTakeoverEnabled = useFt(constants.features.strapiWebsiteTakeover)
  const { dateTime } = useUser()

  const nowDateTime = dayjs(dateTime).startOf('hour').utc().format()

  const filters = {
    and: [
      {
        or: [
          { startsFrom: { null: true } },
          { startsFrom: { lte: nowDateTime } },
        ],
      },
      {
        or: [
          { endsAfter: { null: true } },
          { endsAfter: { gte: nowDateTime } },
        ],
      },
    ],
  }

  const { data, isFetching } = useQuery(websiteTakeoverQuery, {
    client: 'strapi',
    skip: !isStrapiWebsiteTakeoverEnabled,
    variables: {
      filters,
    },
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
    context: {
      ssrCache: true,
    },
  })

  const websiteTakeoverData = data?.websiteTakeovers?.data?.[0]?.attributes

  return {
    isFetching,
    data: websiteTakeoverData,
  }
}

export default useStrapiWSTOData
