import { useMemo } from 'react'
import links, { getLinkWithParams } from 'links'

import { useUser } from 'modules/user'
import { useStrapiWSTOData } from 'modules/brands'

import { type UserGender } from 'typings/graphql'
import { type ComponentBannerWebsiteTakeoverNavigation } from 'typings/strapi.graphql'


const modifyStrapiData = (userGender: UserGender, data): BrandsModule.StrapiWSTONavigationData => {
  const bannersData = data?.banners?.filter(({ __typename, gender }) => {
    if (__typename !== 'ComponentBannerWebsiteTakeoverNavigation') {
      return
    }
    return !gender || gender === 'ALL' || gender === userGender
  }) as ComponentBannerWebsiteTakeoverNavigation[]

  const navLink = getLinkWithParams(links.brand, { name: data?.brandSlug })
  const banners = bannersData?.reduce((navigationBanners, banner) => {
    banner.placement.forEach((placement) => {
      navigationBanners[placement] = {
        name: data.brandName,
        background: {
          mobile: banner?.bannerImage?.mobile?.data?.attributes.url,
          desktop: banner?.bannerImage?.desktop?.data?.attributes.url,
        },
        to: banner?.actionLink || navLink,
      }
    })

    return navigationBanners
  }, {} as BrandsModule.StrapiWSTONavigationData['banners'])

  return bannersData ? {
    navLink,
    banners,
  } : null
}

const useStrapiWSTONavigationData = (): BrandsModule.StrapiWSTONavigationData => {
  const { gender } = useUser()

  const { data, isFetching } = useStrapiWSTOData()

  const strapiData = useMemo(() => {
    return data ? modifyStrapiData(gender, data) : null
  }, [ gender, data ])

  if (isFetching) {
    return null
  }

  return strapiData
}

export default useStrapiWSTONavigationData
