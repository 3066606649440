import { useStrapiWSTOData } from 'modules/brands'


const useStrapiWSTOBrandSlug = (): string => {
  const { data, isFetching } = useStrapiWSTOData()

  if (isFetching) {
    return null
  }

  return data?.brandSlug
}

export default useStrapiWSTOBrandSlug
