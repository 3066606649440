import { useMemo } from 'react'
import { useQuery } from 'apollo-client'

import featuredBrandProductsQuery from './graph/featuredBrandProducts.graphql'


const useStrapiWSTOProducts = (productIds: string[]) => {
  const { data, isFetching } = useQuery(featuredBrandProductsQuery, {
    variables: {
      input: {
        slugs: productIds,
      },
    },
    ssr: false,
  })

  const products = useMemo(() => {
    const productList = data?.productList?.data

    if (!productList) {
      return []
    }

    return productIds.map((productId) => {
      return productList.find((product) => {
        const { id } = product

        return productId === id.toString()
      })
    }).filter(Boolean)
  }, [ data, productIds ])

  return {
    isFetching,
    products,
  }
}

export default useStrapiWSTOProducts
